import request from '@/utils/request'

// 查询采购项目历史事件
export function findAgentPurchaseItemEvents(params) {
  return request({
    url: `/agent_purchase_item_events`,
    method: 'get',
    params: params
  })
}
