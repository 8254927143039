<template>
  <div>
    <a-spin :spinning="loading">
      <a-timeline v-if="data.length > 0">
        <a-timeline-item v-for="event in data" :key="event.id">
          <p>
            <a-space>
              <span>{{ event.created_at }}</span>
              <span>{{ event.event_type }}</span>
              <span>({{ event.operator_full_name }})</span>
            </a-space>
          </p>
          <p>{{ event.content }}</p>
        </a-timeline-item>
      </a-timeline>
      <a-empty v-else />
    </a-spin>
  </div>
</template>

<script>
import { findAgentPurchaseItemEvents } from '@/api/agent_purchase_item_event'

export default {
  name: 'AgentPurchaseItemEventList',
  props: {
    itemId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: [],
      query: {
        page: 1,
        per_page: 100000 // TODO 分页
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      const params = Object.assign({ item_id: this.itemId }, this.query)
      findAgentPurchaseItemEvents(params).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
